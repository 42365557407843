<template>
  <b-modal
    :should-show="shouldShow"
    :on-enter-key-press="upsert"
    hide-footer
    size="xl"
    @hidden="shouldShow = false"
  >
    <template v-slot:modal-title>
      <strong
        >{{ mode === 'create' ? $t('add') : $t('edit') }}
        {{ $t('room') }}</strong
      >
    </template>
    <div class="d-block text-center">
      <div class="row">
        <div class="col-6 text-left">
          <div class="form-group">
            <label>{{ $t('room') }} {{ $t('name') }}:</label>
            <input
              v-model="roomData.name"
              type="text"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-6 text-left">
          <div class="form-group">
            <label>{{ $t('floor') }}:</label>
            <b-form-select v-model="roomData.floorId">
              <option :value="null" disabled>
                {{ $t('selectOption') }}
              </option>
              <option
                v-for="floor in floors"
                :key="floor.id"
                :value="floor.id"
              >
                {{ floor.name }}
              </option>
            </b-form-select>
          </div>
        </div>
        <div
          v-if="isDashboardAdmin"
          class="col-12 text-left form-group"
        >
          <label>{{ $t('svgSnippet') }}:</label>
          <input
            v-model="roomData.svgSnippet"
            type="text"
            class="form-control"
          />
        </div>
        <div
          class="col-6 d-none d-md-inline-block text-left form-group"
        >
          <label>{{ $t('room') }} {{ $t('area') }}:</label>
          <input
            v-model="roomData.settings.m2"
            max-length="5"
            class="form-control"
          />
        </div>
        <div
          class="col-12 d-inline-block d-md-none text-left form-group"
        >
          <label>{{ $t('room') }} {{ $t('area') }}:</label>
          <input
            v-model="roomData.settings.m2"
            max-length="5"
            class="form-control"
          />
        </div>
        <div
          class="col-6 d-none d-md-inline-block text-left form-group"
        >
          <label>{{ $t('room') }} {{ $t('maxOccupants') }}: </label>
          <input
            v-model="roomData.settings.maxOccupants"
            max-length="5"
            class="form-control"
          />
        </div>
        <div
          class="col-12 d-inline-block d-md-none text-left form-group"
        >
          <label>{{ $t('room') }} {{ $t('maxOccupants') }}: </label>
          <input
            v-model="roomData.settings.maxOccupants"
            max-length="5"
            class="form-control"
          />
        </div>
        <div class="col-6 d-none d-md-inline-block text-left">
          <div class="form-group">
            <label>{{ $t('room') }} {{ $t('privacy') }}:</label>
            <b-form-select v-model="roomData.settings.privacySetting">
              <option :value="null">
                {{ $t('selectOption') }}
              </option>
              <option
                v-for="({ value, label }, index) of privacyOptions"
                :key="index"
                :value="value"
              >
                {{ label }}
              </option>
            </b-form-select>
          </div>
        </div>
        <div class="col-12 d-inline-block d-md-none text-left">
          <div class="form-group">
            <label>{{ $t('room') }} {{ $t('privacy') }}:</label>
            <b-form-select v-model="roomData.settings.privacySetting">
              <option :value="null">
                {{ $t('selectOption') }}
              </option>
              <option
                v-for="({ value, label }, index) of privacyOptions"
                :key="index"
                :value="value"
              >
                {{ label }}
              </option>
            </b-form-select>
          </div>
        </div>
        <div class="col-12 d-inline-block d-md-none text-left">
          <div class="form-group">
            <label>{{ $t('room') }} {{ $t('reservable') }}:</label>
            <b-form-select v-model="roomData.reservable">
              <option :value="null">
                {{ $t('selectOption') }}
              </option>
              <option
                v-for="(option, index) in [
                  {
                    value: true,
                    label: 'Reservable'
                  },
                  {
                    value: false,
                    label: 'Not reservable'
                  }
                ]"
                :key="index"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </b-form-select>
          </div>
        </div>
        <div class="col-6 d-none d-md-inline-block text-left">
          <div class="form-group">
            <label>{{ $t('room') }} {{ $t('reservable') }}:</label>
            <b-form-select v-model="roomData.reservable">
              <option :value="null">
                {{ $t('selectOption') }}
              </option>
              <option
                v-for="(option, index) in [
                  {
                    value: true,
                    label: 'Reservable'
                  },
                  {
                    value: false,
                    label: 'Not reservable'
                  }
                ]"
                :key="index"
                :value="option.value"
              >
                {{ option.label }}
              </option>
            </b-form-select>
          </div>
        </div>
        <div v-if="googleIntegration" class="col-6 text-left">
          <div class="form-group">
            <label>Google Rooms:</label>
            <b-form-select
              v-if="googleRooms && googleRooms.length"
              v-model="roomData.resourceEmail"
              :disabled="roomData.emailAddress ? true : false"
            >
              <option value="">
                Select Google Room
              </option>
              <option
                v-for="(option, index) in googleRooms"
                :key="index"
                :value="option.resourceEmail"
              >
                {{ option.generatedResourceName }}
              </option>
            </b-form-select>
            <b-form-select v-else v-model="noRooms" disabled>
              <option :value="null">
                -- No Google Rooms available for binding --
              </option>
            </b-form-select>
          </div>
        </div>
        <div v-if="azureIntegration" class="col-6 text-left">
          <div class="form-group">
            <label>Azure Rooms:</label>
            <b-form-select
              v-if="azureRooms && azureRooms.length"
              v-model="roomData.emailAddress"
              :disabled="roomData.resourceEmail ? true : false"
            >
              <option value="">
                Select Azure Room
              </option>
              <option
                v-for="(option, index) in azureRooms"
                :key="index"
                :value="option.emailAddress"
              >
                {{ option.displayName }}
              </option>
            </b-form-select>
            <b-form-select v-else v-model="noRooms" disabled>
              <option :value="null">
                -- No Azure Rooms available for binding --
              </option>
            </b-form-select>
          </div>
        </div>
      </div>
      <b-button
        variant="danger"
        class="mt-1 mr-3"
        @click="$emit('cancel')"
      >
        {{ $t('cancel') }}
      </b-button>
      <b-button class="mt-1" primary @click="upsert">
        {{ $t('save') }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
import { find as _find } from 'lodash'

import OkkuApi from '@/services/OkkuApi'
import {
  SET_ALERT,
  SET_ROOMS
} from '@/store/modules/common/mutationTypes'
import BootstrapModalHOC from '@/components/hocs/BootstrapModal'
import { ONLY_NUMBER } from '@/constants'

export default {
  components: {
    'b-modal': BootstrapModalHOC
  },
  props: {
    mode: {
      type: String,
      default: 'create'
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    room: {
      type: Object,
      required: true
    },
    floors: {
      type: Array,
      required: true
    },
    googleIntegration: {
      type: Boolean,
      default: false
    },
    azureIntegration: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      noRooms: null,
      privacyOptions: [
        {
          label: this.$i18n.t('showAllDetails'),
          value: 'show all details'
        },
        {
          label: this.$i18n.t('summarizeOccupancy'),
          value: 'summarize occupancy'
        },
        {
          label: this.$i18n.t('hideOccupancy'),
          value: 'hide occupancy'
        },
        {
          label: this.$i18n.t('hideUserDetails'),
          value: 'hide user details'
        }
      ],
      shouldShow: this.show,
      errors: {},
      roomData: {
        settings: {}
      }
    }
  },
  computed: {
    ...mapState('common', {
      isDashboardAdmin: '$isDashboardAdmin',
      googleRooms: 'googleRooms',
      rooms: 'rooms',
      azureRooms: 'azureRooms'
    })
  },
  watch: {
    show() {
      this.shouldShow = this.show
    },
    shouldShow() {
      if (!this.shouldShow) {
        this.$emit('done')
      }
    },
    room(propsData) {
      this.roomData = { ...propsData }
    },
    'roomData.settings': {
      handler(value) {
        this.roomData.settings.m2 = value.m2?.replace(
          ONLY_NUMBER.number,
          ''
        )
        this.roomData.settings.maxOccupants = value.maxOccupants?.replace(
          ONLY_NUMBER.number,
          ''
        )
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async upsert() {
      if (
        this.mode === 'create' &&
        _find(this.rooms, { name: this.roomData.name })
      ) {
        this.$store.commit(`common/${SET_ALERT}`, {
          seconds: 3,
          text: `${this.$t('room')} ${this.$t('entryExists')}`,
          variant: 'danger'
        })
        return false
      }
      const room = { ...this.roomData }
      room.mode = this.mode

      if (!room.bindId) {
        room.mode = 'create'
        delete room.bindId
      }
      try {
        await OkkuApi.updateRooms([room])
        const rooms = await OkkuApi.getRooms()
        this.$store.commit(`common/${SET_ROOMS}`, rooms)
        this.$store.commit(`common/${SET_ALERT}`, {
          seconds: 3,
          text: this.$t('changesSaved'),
          variant: 'success'
        })
        this.$emit('done')
      } catch (error) {
        let { message, response } = error
        if (Array.isArray(response?.data?.errors)) {
          message = response?.data?.errors[0]?.msg
        }
        this.$store.commit(`common/${SET_ALERT}`, {
          seconds: 5,
          text: message,
          variant: 'danger'
        })
      }
      return true
    }
  }
}
</script>

<style lang="scss" scoped></style>
